import { Candidate, ElectionRole, Task } from '../interfaces/config';
import { executeString } from './Text';
import { teamRoleStrings } from '../constants/team-role-strings';

export interface CandidateProps {
    committeeName: string;
    publicUrl: string;
    role: string;
    index: number;
    candidate: Candidate;
}

export function CandidateComponent(props: CandidateProps) {
    return (
        <div className="team-member">
            <img
                className="mx-auto rounded-circle"
                src={`${props.publicUrl}/img/elections/${props.committeeName}/${props.role}_${props.index}.jpg`}
                alt=""
            />
            <h5>
                {props.candidate.firstName} {props.candidate.lastName}
            </h5>
            <p className="text-muted">
                {props.candidate.year}
                <sup>{props.candidate.year === 1 ? 're' : 'e'}</sup> année
            </p>
            <div className="speech">
                {props.candidate.speech.split('\n').map((paragraph: string, index: number) => (
                    <p key={'paragraph' + index}>{paragraph}</p>
                ))}
            </div>
        </div>
    );
}

export interface TaskProps {
    task: Task;
}

export function TaskComponent(props: TaskProps) {
    return (
        <>
            <li>{executeString(props.task.description)}</li>

            {props.task.subtasks.length > 0 ? (
                <ul>
                    {props.task.subtasks.map((task: Task) => (
                        <>
                            <TaskComponent task={task} />
                        </>
                    ))}
                </ul>
            ) : (
                <></>
            )}
        </>
    );
}

export interface RoleProps {
    role: ElectionRole;
}

export function RoleComponent(props: RoleProps) {
    return (
        <>
            <div className="col" id={props.role.id_name}>
                <h4>{props.role.name}</h4>
                <p style={{ textAlign: 'justify' }}>{executeString(props.role.description)}</p>
                <h6>Tâches</h6>
                <ul>
                    {props.role.tasks.map((task: Task) => (
                        <>
                            <TaskComponent task={task} />
                        </>
                    ))}
                </ul>
                <br />
                {props.role.note.length > 0 ? <strong>À NOTER: {executeString(props.role.note)}</strong> : <></>}
            </div>
            <hr />
        </>
    );
}

export interface RoleCandidatesProps {
    committeeName: string;
    publicUrl: string;
    roleName: string;
    role: ElectionRole;
}

export function RoleCandidatesComponent(props: RoleCandidatesProps) {
    return (
        <div>
            <h4>{teamRoleStrings.get(props.roleName)}</h4>
            <div>
                {props.role.candidates.length > 0 ? (
                    props.role.candidates.map((candidate: Candidate, index: number) => (
                        <CandidateComponent
                            key={'team' + index}
                            committeeName={props.committeeName}
                            publicUrl={props.publicUrl}
                            role={props.roleName}
                            index={index}
                            candidate={candidate}
                        />
                    ))
                ) : (
                    <div className="team-member">
                        <h5>Aucun candidat</h5>
                    </div>
                )}
            </div>
            <hr />
        </div>
    );
}

export interface CandidateSectionProps {
    committeeName: string;
    publicUrl: string;
    electionMapRoles: Array<[string, ElectionRole]>;
}

export function CandidateSectionComponent(props: CandidateSectionProps) {
    return (
        <div className="container">
            <div className="text-center mb-4">
                <h2 className="section-heading text-uppercase">Liste des candidats</h2>
                <button
                    className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
                    data-toggle="collapse"
                    data-target="#listecandidats"
                >
                    Voir les candidats
                </button>
            </div>
            <div id="listecandidats" className="collapse">
                {props.electionMapRoles.map(
                    ([role, value]: [string, ElectionRole]) =>
                        value.isEnabled && (
                            <RoleCandidatesComponent
                                committeeName={props.committeeName}
                                publicUrl={props.publicUrl}
                                key={role}
                                roleName={role}
                                role={value}
                            />
                        )
                )}
            </div>
        </div>
    );
}

export interface RoleSectionProps {
    electionMapRoles: Array<[string, ElectionRole]>;
}

export function RoleSectionComponent(props: RoleSectionProps) {
    return (
        <div className="container">
            <div className="text-center mb-4">
                <h2 className="section-heading text-uppercase">Description des postes</h2>
                <button
                    className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
                    data-toggle="collapse"
                    data-target="#listepostes"
                >
                    Voir les postes disponibles
                </button>
            </div>
            <div className="collapse" id="listepostes">
                {props.electionMapRoles.map(
                    ([role, value]: [string, ElectionRole]) =>
                        value.isEnabled && <RoleComponent role={value} key={role} />
                )}
            </div>
        </div>
    );
}
