import React from 'react';

import config from '../config/config';
import { HeaderComponent, FooterComponent } from '../components/Margins';

import './Elections.scss';
import { NavigationHeaderComponent, NavigationItemComponent } from '../components/Navigation';
import { CandidateSectionComponent, RoleSectionComponent } from '../components/Candidate';
import { ApplicationSectionComponent, IntroductionSectionRender } from '../components/Elections';

const showCandidates = false;

const electionRoles = Object.entries(config.elections);

const Elections: React.FC = () => (
    <>
        {/* Navigation */}
        <NavigationHeaderComponent
            title={config.title}
            menuHref={'/'}
            anchors={[
                <NavigationItemComponent anchorName={'Postes'} key={'postes'} />,
                <NavigationItemComponent anchorName={'Candidats'} condition={showCandidates} key={'candidats'} />,
                <NavigationItemComponent anchorName={'Application'} key={'application'} />,
            ]}
        />

        {/* Header */}
        <HeaderComponent
            headerContent={
                <div className="intro-heading text-uppercase">
                    Élections <br /> - <br /> Comité étudiant
                    <br />
                    Génie {config.extendedName}
                </div>
            }
        />

        {/* Introduction */}
        <section className="bg-light page-section" id="introduction">
            <IntroductionSectionRender extendedName={config.extendedName} title={config.title} />
        </section>

        {/* Role description */}
        <section className="page-section" id="postes">
            <RoleSectionComponent electionMapRoles={electionRoles} />
        </section>

        {/* Candidates */}
        {showCandidates && (
            <section className="bg-light page-section" id="candidats">
                <CandidateSectionComponent
                    committeeName={config.name}
                    publicUrl={process.env.PUBLIC_URL}
                    electionMapRoles={electionRoles}
                />
            </section>
        )}

        {/* Application */}
        <section className="page-section" id="application">
            <ApplicationSectionComponent
                title={config.title}
                electionDeadline={config.electionDeadline}
                electionsEmail={config.electionsEmail}
            />
        </section>

        {/* Footer */}
        <FooterComponent title={config.title} facebookUrl={config.facebookUrl} />
    </>
);

export default Elections;
